/* eslint-disable @typescript-eslint/no-use-before-define */
const dev = 'https://api.status.dev.plus1connect.danfoss.com'
const stg = 'https://api.status.stg.plus1connect.danfoss.com'
const prd = 'https://api.status.plus1connect.danfoss.com'
const sbx = 'https://api.status.sbx.plus1connect.danfoss.com'

// const dynatraceStagingURL = 'https://yea78419.live.dynatrace.com'
const dynatraceProductionURL = 'https://rgt28469.live.dynatrace.com'
const dynatraceSandboxURL = 'https://svh89134.live.dynatrace.com'

export const timeseriesId2xx =
  'com.dynatrace.builtin:synthetic.httpmonitor.location.response.2xx'
export const timeseriesIdAvailability =
  'com.dynatrace.builtin:synthetic.httpmonitor.availability'
export const timeseriesIdAvailabilityPortal =
  'com.dynatrace.builtin:syntheticmonitor.availability'
export const timeseriesIdPerformance =
  'com.dynatrace.builtin:synthetic.httpmonitor.location.performance.duration'
export const timeseriesIdPerformanceSingleCall =
  'com.dynatrace.builtin:synthetic.httpmonitor.location.performance.duration'
export const timeseriesId4xx =
  'com.dynatrace.builtin:synthetic.httpmonitor.location.response.4xx'

export const defaultParams =
  'source=BUILTIN&detailedSource=o1wOjR2jR_6Z4QfSqouZu'
export const paramIncludeData = 'includeData=true'
export const paramAggregationType = 'aggregationType=AVG'
export const paramRelativeTimeHour = 'relativeTime=hour'

// const stagingGetDeviceByDeviceIdAvailDataPoint =
//   'HTTP_CHECK-2602C36DDE1ACFD6, SYNTHETIC_LOCATION-6C525F0EAF6F48DD'
// const stagingActivateDeviceAvailDataPoint =
//   'HTTP_CHECK-4B06059AB7AFDC91, SYNTHETIC_LOCATION-6C525F0EAF6F48DD'
// const stagingApplyConfigurationsAvailDataPoint =
//   'HTTP_CHECK-A4BA2BDAB2EE4331, SYNTHETIC_LOCATION-6C525F0EAF6F48DD'
// const stagingApplyFotaAvailDataPoint =
//   'HTTP_CHECK-29AD3C04D8E2B3BB, SYNTHETIC_LOCATION-6C525F0EAF6F48DD'
// const stagingBiDirectionalCommAvailDataPoint =
//   'HTTP_CHECK-A88BEAB63C9C3861, SYNTHETIC_LOCATION-6C525F0EAF6F48DD'
// const stagingAggregationServiceDataPoint =
//   'HTTP_CHECK-FDB3CA140170634B, SYNTHETIC_LOCATION-6C525F0EAF6F48DD'
// const stagingApiGatewayServiceDataPoint =
//   'HTTP_CHECK-BCAB814BBD042293, SYNTHETIC_LOCATION-6C525F0EAF6F48DD'
// const stagingAuthorizationServiceDataPoint =
//   'HTTP_CHECK-6982A7467B7A1B0E, SYNTHETIC_LOCATION-6C525F0EAF6F48DD'
// const stagingCustomerServiceDataPoint =
//   'HTTP_CHECK-02A7EA7267AEE880, SYNTHETIC_LOCATION-6C525F0EAF6F48DD'
// const stagingDeviceServiceDataPoint =
//   'HTTP_CHECK-85A0D125E4A8907C, SYNTHETIC_LOCATION-6C525F0EAF6F48DD'
// const stagingRulesServiceDataPoint =
//   'HTTP_CHECK-19881417B4EB8EE5, SYNTHETIC_LOCATION-6C525F0EAF6F48DD'
// const stagingGetDeviceByDeviceIdPerformDataPoint =
//   'HTTP_CHECK_STEP-8D95FAD219E4E17C, SYNTHETIC_LOCATION-6C525F0EAF6F48DD'
// const stagingActivateDevicePerformDataPoint =
//   'HTTP_CHECK_STEP-30986A2C79D78ECE, SYNTHETIC_LOCATION-6C525F0EAF6F48DD'
// const stagingApplyConfigurationsPerformDataPoint =
//   'HTTP_CHECK_STEP-8CD3437D480C8BD9, SYNTHETIC_LOCATION-6C525F0EAF6F48DD'
// const stagingApplyFotaPerformDataPoint =
//   'HTTP_CHECK_STEP-94587DA7568ADF2E, SYNTHETIC_LOCATION-6C525F0EAF6F48DD'
// const stagingBiDirectionalCommPerformDataPoint =
//   'HTTP_CHECK_STEP-71B7CA11CC22FFA4, SYNTHETIC_LOCATION-6C525F0EAF6F48DD'

const productionGetDeviceByDeviceIdAvailDataPoint =
  'HTTP_CHECK-BBF4EEB5C06E91A1, GEOLOCATION-8CE0B526122F75AB'
const productionActivateDeviceAvailDataPoint =
  'HTTP_CHECK-E2E84CD5A8C0327D, GEOLOCATION-1C29237942E4437C'
const productionApplyConfigurationsAvailDataPoint =
  'HTTP_CHECK-6709DC2FBB087EC9, GEOLOCATION-8CE0B526122F75AB'
const productionApplyFotaAvailDataPoint =
  'HTTP_CHECK-B590448FC75B1A69, GEOLOCATION-8CE0B526122F75AB'
const productionBiDirectionalCommAvailDataPoint =
  'HTTP_CHECK-1C84E50D4E31438B, GEOLOCATION-8CE0B526122F75AB'
const productionAggregationServiceDataPoint =
  'HTTP_CHECK-0D7CD1022E003DCA, GEOLOCATION-1C29237942E4437C'
const productionApiGatewayServiceDataPoint =
  'HTTP_CHECK-E5E03D4F2F719F29, GEOLOCATION-1C29237942E4437C'
const productionAuthorizationServiceDataPoint =
  'HTTP_CHECK-D0DB2D16E8AC09FD, GEOLOCATION-1C29237942E4437C'
const productionCustomerServiceDataPoint =
  'HTTP_CHECK-38711107D7F074BF, GEOLOCATION-1C29237942E4437C'
const productionDeviceServiceDataPoint =
  'HTTP_CHECK-C1FDD9381B9866EC, GEOLOCATION-1C29237942E4437C'
const productionRulesServiceDataPoint =
  'HTTP_CHECK-7F691DAB48851572, GEOLOCATION-1C29237942E4437C'
const productionGetDeviceByDeviceIdPerformDataPoint =
  'HTTP_CHECK-BBF4EEB5C06E91A1, GEOLOCATION-8CE0B526122F75AB'
const productionActivateDevicePerformDataPoint =
  'HTTP_CHECK_STEP-B7F470C528F036CD, GEOLOCATION-1C29237942E4437C'
const productionApplyConfigurationsPerformDataPoint =
  'HTTP_CHECK-6709DC2FBB087EC9, GEOLOCATION-8CE0B526122F75AB'
const productionApplyFotaPerformDataPoint =
  'HTTP_CHECK-B590448FC75B1A69, GEOLOCATION-8CE0B526122F75AB'
const productionBiDirectionalCommPerformDataPoint =
  'HTTP_CHECK-1C84E50D4E31438B, GEOLOCATION-8CE0B526122F75AB'

const productionPortalLoginDataPoint =
  'SYNTHETIC_TEST-0EB396B4DA4C9A6D, GEOLOCATION-1C29237942E4437C'

//Portal Device Configuration
const productionPortalApplyConfigDataPoint =
  'SYNTHETIC_TEST-1430992D42298FA3, GEOLOCATION-1C29237942E4437C'
const productionPortalCreateConfigDataPoint =
  'SYNTHETIC_TEST-5798ECE6A115040C, GEOLOCATION-1C29237942E4437C'
const productionPortalEditConfigDataPoint =
  'SYNTHETIC_TEST-11BFBAEA6B1C1A6E, GEOLOCATION-1C29237942E4437C'
const productionPortalViewConfigDataPoint =
  'SYNTHETIC_TEST-661A00AA24D51427, GEOLOCATION-1C29237942E4437C'
const productionPortalDeleteConfigDataPoint =
  'SYNTHETIC_TEST-6B57F1A62CAEB127, GEOLOCATION-1C29237942E4437C'

//Portal Firmware
const productionPortalApplyFirmwareDataPoint =
  'SYNTHETIC_TEST-4BB3362715300A1D, GEOLOCATION-1C29237942E4437C'
const productionPortalViewFirmwareDataPoint =
  'SYNTHETIC_TEST-03A769DE0F856AC6, GEOLOCATION-1C29237942E4437C'

//Portal Dashboard
const productionPortalAddDashboardDataPoint =
  'SYNTHETIC_TEST-5E75E53E2EA83B4E, GEOLOCATION-1C29237942E4437C'
const productionPortalEditDashboardPoint =
  'SYNTHETIC_TEST-14E4CF3F8FE4B0A4, GEOLOCATION-1C29237942E4437C'
const productionPortalDeleteDashboardDataPoint =
  'SYNTHETIC_TEST-7076BA9C3CF2EC79, GEOLOCATION-1C29237942E4437C'

//Portal Map
const productionPortalLastLocationMapDataPoint =
  'SYNTHETIC_TEST-45CDF9F0B544F5D9, GEOLOCATION-1C29237942E4437C'
const productionPortalDashboardViewMapDataPoint =
  'SYNTHETIC_TEST-571276AA0AA02170, GEOLOCATION-1C29237942E4437C'

//Portal Geotracking
const productionPortalRealTimeTrackingDataPoint =
  'SYNTHETIC_TEST-2A3143EA138EBFC0, GEOLOCATION-1C29237942E4437C'
const productionPortalHistoricalTrackingDataPoint =
  'SYNTHETIC_TEST-499C0E5D0B32B0BA, GEOLOCATION-1C29237942E4437C'

//Portal Report
const productionPortalCreateReportDataPoint =
  'SYNTHETIC_TEST-57C175B98422B958, GEOLOCATION-1C29237942E4437C'
const productionPortalEditReportDataPoint =
  'SYNTHETIC_TEST-76CF89819A76C418, GEOLOCATION-1C29237942E4437C'
const productionPortalDeleteReportDataPoint =
  'SYNTHETIC_TEST-3957306D140E5E8B, GEOLOCATION-1C29237942E4437C'

//Portal Chart
const productionPortalCreateChartDataPoint =
  'SYNTHETIC_TEST-3263A69C7E09FE9A, GEOLOCATION-1C29237942E4437C'

//Portal Event History
const productionPortalSearchEventDataPoint =
  'SYNTHETIC_TEST-12CF174A92567DC3, GEOLOCATION-1C29237942E4437C'
const productionPortalViewEventDetailsDataPoint =
  'SYNTHETIC_TEST-327C52824EBFA794, GEOLOCATION-1C29237942E4437C'
const productionPortalRelaunchActionDataPoint =
  'SYNTHETIC_TEST-2AC3A70C0ED7CE81, GEOLOCATION-1C29237942E4437C'

//Portal Device Management
const productionPortalEditDeviceDetailsDataPoint =
  'SYNTHETIC_TEST-5DA2D4DE0F31E36B, GEOLOCATION-1C29237942E4437C'

const sandboxGetDeviceByDeviceIdAvailDataPoint =
  'HTTP_CHECK-A0CB49F4C4D11970, GEOLOCATION-8CE0B526122F75AB'
const sandboxActivateDeviceAvailDataPoint =
  'HTTP_CHECK-E8C65B9A334FBD91, GEOLOCATION-8CE0B526122F75AB'
const sandboxApplyConfigurationsAvailDataPoint =
  'HTTP_CHECK-86F4FC86A99D2437, GEOLOCATION-49E2F2BFBEAA9C71'
const sandboxApplyFotaAvailDataPoint =
  'HTTP_CHECK-99C8BD2A48A1A75D, GEOLOCATION-8CE0B526122F75AB'
const sandboxBiDirectionalCommAvailDataPoint =
  'HTTP_CHECK-9F75E62F7721C9F0, GEOLOCATION-8CE0B526122F75AB'
const sandboxAggregationServiceDataPoint =
  'HTTP_CHECK-C910804BCE56CAAF, GEOLOCATION-1C29237942E4437C'
const sandboxApiGatewayServiceDataPoint =
  'HTTP_CHECK-CD2F558032908CE6, GEOLOCATION-1C29237942E4437C'
const sandboxAuthorizationServiceDataPoint =
  'HTTP_CHECK-3B24939930364AF6, GEOLOCATION-1C29237942E4437C'
const sandboxCustomerServiceDataPoint =
  'HTTP_CHECK-735C92ABF8655E6A, GEOLOCATION-1C29237942E4437C'
const sandboxDeviceServiceDataPoint =
  'HTTP_CHECK-19AC77B9827E2941, GEOLOCATION-1C29237942E4437C'
const sandboxRulesServiceDataPoint = null
const sandboxGetDeviceByDeviceIdPerformDataPoint =
  'HTTP_CHECK-A0CB49F4C4D11970, GEOLOCATION-8CE0B526122F75AB'
const sandboxActivateDevicePerformDataPoint =
  'HTTP_CHECK_STEP-384D6EAE07851DD7, GEOLOCATION-1C29237942E4437C'
const sandboxApplyConfigurationsPerformDataPoint =
  'HTTP_CHECK-86F4FC86A99D2437, GEOLOCATION-49E2F2BFBEAA9C71'
const sandboxApplyFotaPerformDataPoint =
  'HTTP_CHECK-99C8BD2A48A1A75D, GEOLOCATION-8CE0B526122F75AB'
const sandboxBiDirectionalCommPerformDataPoint =
  'HTTP_CHECK-9F75E62F7721C9F0, GEOLOCATION-8CE0B526122F75AB'
const sandboxProxyServerDataPoint =
  'HTTP_CHECK-36A0952AC0B981E9, GEOLOCATION-1C29237942E4437C'
const sandboxRCTDataPoint =
  'HTTP_CHECK-65F76FA54E8D0DEB, GEOLOCATION-1C29237942E4437C'
const sandboxRCTPerformDataPoint =
  'HTTP_CHECK_STEP-0D61A9DE24C81118, GEOLOCATION-1C29237942E4437C'
const sandboxDIPDataPoint =
  'HTTP_CHECK-9BE7A79F2FEA76E9, GEOLOCATION-1C29237942E4437C'
const sandboxPortalLoginDataPoint =
  'SYNTHETIC_TEST-58BF2903DF4AD77F, GEOLOCATION-1C29237942E4437C'

//Portal Device Configuration
const sandboxPortalApplyConfigDataPoint =
  'SYNTHETIC_TEST-40E1123D30D85233, GEOLOCATION-1C29237942E4437C'
const sandboxPortalCreateConfigDataPoint =
  'SYNTHETIC_TEST-6A12B6DA1E761135, GEOLOCATION-1C29237942E4437C'
const sandboxPortalEditConfigDataPoint =
  'SYNTHETIC_TEST-513292D56DF0D692, GEOLOCATION-1C29237942E4437C'
const sandboxPortalViewConfigDataPoint =
  'SYNTHETIC_TEST-0C2CA2AEBFBA6470, GEOLOCATION-1C29237942E4437C'
const sandboxPortalDeleteConfigDataPoint =
  'SYNTHETIC_TEST-37632CC6CB590019, GEOLOCATION-1C29237942E4437C'

//Portal Firmware
const sandboxPortalApplyFirmwareDataPoint =
  'SYNTHETIC_TEST-74227DFD4993849A, GEOLOCATION-1C29237942E4437C'
const sandboxPortalViewFirmwareDataPoint =
  'SYNTHETIC_TEST-74227DFD4993849A, GEOLOCATION-1C29237942E4437C'

//Portal Dashboard
const sandboxPortalAddDashboardDataPoint =
  'SYNTHETIC_TEST-1B0F5D9FC7974EA4, GEOLOCATION-1C29237942E4437C'
const sandboxPortalEditDashboardPoint =
  'SYNTHETIC_TEST-33F3EAD6DE80456D, GEOLOCATION-1C29237942E4437C'
const sandboxPortalDeleteDashboardDataPoint =
  'SYNTHETIC_TEST-6D1494EC92C6DDA1, GEOLOCATION-1C29237942E4437C'

//Portal Map
const sandboxPortalLastLocationMapDataPoint =
  'SYNTHETIC_TEST-75F2764D2F0B15EF, GEOLOCATION-1C29237942E4437C'
const sandboxPortalDashboardViewMapDataPoint =
  'SYNTHETIC_TEST-711F820A26B0CB2C, GEOLOCATION-1C29237942E4437C'

//Portal Geotracking
const sandboxPortalRealTimeTrackingDataPoint =
  'SYNTHETIC_TEST-3FE34C1A6CDA5745, GEOLOCATION-1C29237942E4437C'
const sandboxPortalHistoricalTrackingDataPoint =
  'SYNTHETIC_TEST-3080E20F355D0378, GEOLOCATION-1C29237942E4437C'

//Portal Report
const sandboxPortalCreateReportDataPoint =
  'SYNTHETIC_TEST-0D45CD161EA53729, GEOLOCATION-1C29237942E4437C'
const sandboxPortalEditReportDataPoint =
  'SYNTHETIC_TEST-456405FA9849AD8C, GEOLOCATION-1C29237942E4437C'
const sandboxPortalDeleteReportDataPoint =
  'SYNTHETIC_TEST-69F5281FDEC04D0B, GEOLOCATION-1C29237942E4437C'

//Portal Chart
const sandboxPortalCreateChartDataPoint =
  'SYNTHETIC_TEST-4D1691EAF4370547, GEOLOCATION-1C29237942E4437C'

//Portal Event History
const sandboxPortalSearchEventDataPoint =
  'SYNTHETIC_TEST-75302C9BEE539394, GEOLOCATION-1C29237942E4437C'
const sandboxPortalViewEventDetailsDataPoint =
  'SYNTHETIC_TEST-59EB0CEDE24A80D7, GEOLOCATION-1C29237942E4437C'
const sandboxPortalRelaunchActionDataPoint =
  'SYNTHETIC_TEST-343B8B4B245D3006, GEOLOCATION-1C29237942E4437C'

//Portal Device Management
const sandboxPortalEditDeviceDetailsDataPoint =
  'SYNTHETIC_TEST-6D9B54A7FC93DD77, GEOLOCATION-1C29237942E4437C'

// const authTokenDynatraceStaging = 'Api-Token o1wOjR2jR_6Z4QfSqouZu'
const authTokenDynatraceProduction = 'Api-Token AoJrrRGpTW6GMU1AbLfeV'
const authTokenDynatraceSandbox = 'Api-Token Ub9j4UHrS3aRfz1zCsslV'

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const setDynatraceURL = apiURL => {
  console.log('envURL apiurl:', apiURL)
  if (apiURL === dev) {
    dynatraceEnvURL = dynatraceProductionURL
    authToken = authTokenDynatraceProduction
    entityGetDeviceByDeviceIdAvailDataPoint = productionGetDeviceByDeviceIdAvailDataPoint
    entityActivateDeviceAvailDataPoint = productionActivateDeviceAvailDataPoint
    entityApplyConfigurationsAvailDataPoint = productionApplyConfigurationsAvailDataPoint
    entityApplyFotaAvailDataPoint = productionApplyFotaAvailDataPoint
    entityBiDirectionalCommAvailDataPoint = productionBiDirectionalCommAvailDataPoint
    entityAggregationServiceDataPoint = productionAggregationServiceDataPoint
    entityApiGatewayServiceDataPoint = productionApiGatewayServiceDataPoint
    entityAuthorizationServiceDataPoint = productionAuthorizationServiceDataPoint
    entityCustomerServiceDataPoint = productionCustomerServiceDataPoint
    entityDeviceServiceDataPoint = productionDeviceServiceDataPoint
    entityRulesServiceDataPoint = productionRulesServiceDataPoint
    entityGetDeviceByDeviceIdPerformDataPoint = productionGetDeviceByDeviceIdPerformDataPoint
    entityActivateDevicePerformDataPoint = productionActivateDevicePerformDataPoint
    entityApplyConfigurationsPerformDataPoint = productionApplyConfigurationsPerformDataPoint
    entityApplyFotaPerformDataPoint = productionApplyFotaPerformDataPoint
    entityBiDirectionalCommPerformDataPoint = productionBiDirectionalCommPerformDataPoint
    entityPortalLoginDataPoint = productionPortalLoginDataPoint
    entityPortalApplyConfigDataPoint = productionPortalApplyConfigDataPoint
    entityPortalCreateConfigDataPoint = productionPortalCreateConfigDataPoint
    entityPortalEditConfigDataPoint = productionPortalEditConfigDataPoint
    entityPortalViewConfigDataPoint = productionPortalViewConfigDataPoint
    entityPortalDeleteConfigDataPoint = productionPortalDeleteConfigDataPoint
    entityPortalApplyFirmwareDataPoint = productionPortalApplyFirmwareDataPoint
    entityPortalViewFirmwareDataPoint = productionPortalViewFirmwareDataPoint
    entityPortalAddDashboardDataPoint = productionPortalAddDashboardDataPoint
    entityPortalEditDashboardDataPoint = productionPortalEditDashboardPoint
    entityPortalDeleteDashboardDataPoint = productionPortalDeleteDashboardDataPoint
    entityPortalLastLocationMapDataPoint = productionPortalLastLocationMapDataPoint
    entityPortalDashboardViewMapDataPoint = productionPortalDashboardViewMapDataPoint
    entityPortalRealTimeTrackingDataPoint = productionPortalRealTimeTrackingDataPoint
    entityPortalHistoricalTrackingDataPoint = productionPortalHistoricalTrackingDataPoint
    entityPortalCreateReportDataPoint = productionPortalCreateReportDataPoint
    entityPortalEditReportDataPoint = productionPortalEditReportDataPoint
    entityPortalDeleteReportDataPoint = productionPortalDeleteReportDataPoint
    entityPortalCreateChartDataPoint = productionPortalCreateChartDataPoint
    entityPortalSearchEventDataPoint = productionPortalSearchEventDataPoint
    entityPortalViewEventDetailsDataPoint = productionPortalViewEventDetailsDataPoint
    entityPortalRelaunchActionDataPoint = productionPortalRelaunchActionDataPoint
    entityPortalEditDeviceDetailsDataPoint = productionPortalEditDeviceDetailsDataPoint
  } else if (apiURL === stg) {
    dynatraceEnvURL = dynatraceSandboxURL
    authToken = authTokenDynatraceSandbox
    entityGetDeviceByDeviceIdAvailDataPoint = sandboxGetDeviceByDeviceIdAvailDataPoint
    entityActivateDeviceAvailDataPoint = sandboxActivateDeviceAvailDataPoint
    entityApplyConfigurationsAvailDataPoint = sandboxApplyConfigurationsAvailDataPoint
    entityApplyFotaAvailDataPoint = sandboxApplyFotaAvailDataPoint
    entityBiDirectionalCommAvailDataPoint = sandboxBiDirectionalCommAvailDataPoint
    entityAggregationServiceDataPoint = sandboxAggregationServiceDataPoint
    entityApiGatewayServiceDataPoint = sandboxApiGatewayServiceDataPoint
    entityAuthorizationServiceDataPoint = sandboxAuthorizationServiceDataPoint
    entityCustomerServiceDataPoint = sandboxCustomerServiceDataPoint
    entityDeviceServiceDataPoint = sandboxDeviceServiceDataPoint
    entityRulesServiceDataPoint = sandboxRulesServiceDataPoint
    entityGetDeviceByDeviceIdPerformDataPoint = sandboxGetDeviceByDeviceIdPerformDataPoint
    entityActivateDevicePerformDataPoint = sandboxActivateDevicePerformDataPoint
    entityApplyConfigurationsPerformDataPoint = sandboxApplyConfigurationsPerformDataPoint
    entityApplyFotaPerformDataPoint = sandboxApplyFotaPerformDataPoint
    entityBiDirectionalCommPerformDataPoint = sandboxBiDirectionalCommPerformDataPoint
    entityProxyServerDataPoint = sandboxProxyServerDataPoint
    entityRCTDataPoint = sandboxRCTDataPoint
    entityRCTPerformDataPoint = sandboxRCTPerformDataPoint
    entityDIPDataPoint = sandboxDIPDataPoint
    entityPortalLoginDataPoint = sandboxPortalLoginDataPoint
    entityPortalApplyConfigDataPoint = sandboxPortalApplyConfigDataPoint
    entityPortalCreateConfigDataPoint = sandboxPortalCreateConfigDataPoint
    entityPortalEditConfigDataPoint = sandboxPortalEditConfigDataPoint
    entityPortalViewConfigDataPoint = sandboxPortalViewConfigDataPoint
    entityPortalDeleteConfigDataPoint = sandboxPortalDeleteConfigDataPoint
    entityPortalApplyFirmwareDataPoint = sandboxPortalApplyFirmwareDataPoint
    entityPortalViewFirmwareDataPoint = sandboxPortalViewFirmwareDataPoint
    entityPortalAddDashboardDataPoint = sandboxPortalAddDashboardDataPoint
    entityPortalEditDashboardDataPoint = sandboxPortalEditDashboardPoint
    entityPortalDeleteDashboardDataPoint = sandboxPortalDeleteDashboardDataPoint
    entityPortalLastLocationMapDataPoint = sandboxPortalLastLocationMapDataPoint
    entityPortalDashboardViewMapDataPoint = sandboxPortalDashboardViewMapDataPoint
    entityPortalRealTimeTrackingDataPoint = sandboxPortalRealTimeTrackingDataPoint
    entityPortalHistoricalTrackingDataPoint = sandboxPortalHistoricalTrackingDataPoint
    entityPortalCreateReportDataPoint = sandboxPortalCreateReportDataPoint
    entityPortalEditReportDataPoint = sandboxPortalEditReportDataPoint
    entityPortalDeleteReportDataPoint = sandboxPortalDeleteReportDataPoint
    entityPortalCreateChartDataPoint = sandboxPortalCreateChartDataPoint
    entityPortalSearchEventDataPoint = sandboxPortalSearchEventDataPoint
    entityPortalViewEventDetailsDataPoint = sandboxPortalViewEventDetailsDataPoint
    entityPortalRelaunchActionDataPoint = sandboxPortalRelaunchActionDataPoint
    entityPortalEditDeviceDetailsDataPoint = sandboxPortalEditDeviceDetailsDataPoint
  } else if (apiURL === prd) {
    dynatraceEnvURL = dynatraceProductionURL
    authToken = authTokenDynatraceProduction
    entityGetDeviceByDeviceIdAvailDataPoint = productionGetDeviceByDeviceIdAvailDataPoint
    entityActivateDeviceAvailDataPoint = productionActivateDeviceAvailDataPoint
    entityApplyConfigurationsAvailDataPoint = productionApplyConfigurationsAvailDataPoint
    entityApplyFotaAvailDataPoint = productionApplyFotaAvailDataPoint
    entityBiDirectionalCommAvailDataPoint = productionBiDirectionalCommAvailDataPoint
    entityAggregationServiceDataPoint = productionAggregationServiceDataPoint
    entityApiGatewayServiceDataPoint = productionApiGatewayServiceDataPoint
    entityAuthorizationServiceDataPoint = productionAuthorizationServiceDataPoint
    entityCustomerServiceDataPoint = productionCustomerServiceDataPoint
    entityDeviceServiceDataPoint = productionDeviceServiceDataPoint
    entityRulesServiceDataPoint = productionRulesServiceDataPoint
    entityGetDeviceByDeviceIdPerformDataPoint = productionGetDeviceByDeviceIdPerformDataPoint
    entityActivateDevicePerformDataPoint = productionActivateDevicePerformDataPoint
    entityApplyConfigurationsPerformDataPoint = productionApplyConfigurationsPerformDataPoint
    entityApplyFotaPerformDataPoint = productionApplyFotaPerformDataPoint
    entityBiDirectionalCommPerformDataPoint = productionBiDirectionalCommPerformDataPoint
    entityPortalLoginDataPoint = productionPortalLoginDataPoint
    entityPortalApplyConfigDataPoint = productionPortalApplyConfigDataPoint
    entityPortalCreateConfigDataPoint = productionPortalCreateConfigDataPoint
    entityPortalEditConfigDataPoint = productionPortalEditConfigDataPoint
    entityPortalViewConfigDataPoint = productionPortalViewConfigDataPoint
    entityPortalDeleteConfigDataPoint = productionPortalDeleteConfigDataPoint
    entityPortalApplyFirmwareDataPoint = productionPortalApplyFirmwareDataPoint
    entityPortalViewFirmwareDataPoint = productionPortalViewFirmwareDataPoint
    entityPortalAddDashboardDataPoint = productionPortalAddDashboardDataPoint
    entityPortalEditDashboardDataPoint = productionPortalEditDashboardPoint
    entityPortalDeleteDashboardDataPoint = productionPortalDeleteDashboardDataPoint
    entityPortalLastLocationMapDataPoint = productionPortalLastLocationMapDataPoint
    entityPortalDashboardViewMapDataPoint = productionPortalDashboardViewMapDataPoint
    entityPortalRealTimeTrackingDataPoint = productionPortalRealTimeTrackingDataPoint
    entityPortalHistoricalTrackingDataPoint = productionPortalHistoricalTrackingDataPoint
    entityPortalCreateReportDataPoint = productionPortalCreateReportDataPoint
    entityPortalEditReportDataPoint = productionPortalEditReportDataPoint
    entityPortalDeleteReportDataPoint = productionPortalDeleteReportDataPoint
    entityPortalCreateChartDataPoint = productionPortalCreateChartDataPoint
    entityPortalSearchEventDataPoint = productionPortalSearchEventDataPoint
    entityPortalViewEventDetailsDataPoint = productionPortalViewEventDetailsDataPoint
    entityPortalRelaunchActionDataPoint = productionPortalRelaunchActionDataPoint
    entityPortalEditDeviceDetailsDataPoint = productionPortalEditDeviceDetailsDataPoint
  } else if (apiURL === sbx) {
    dynatraceEnvURL = dynatraceSandboxURL
    authToken = authTokenDynatraceSandbox
    entityGetDeviceByDeviceIdAvailDataPoint = sandboxGetDeviceByDeviceIdAvailDataPoint
    entityActivateDeviceAvailDataPoint = sandboxActivateDeviceAvailDataPoint
    entityApplyConfigurationsAvailDataPoint = sandboxApplyConfigurationsAvailDataPoint
    entityApplyFotaAvailDataPoint = sandboxApplyFotaAvailDataPoint
    entityBiDirectionalCommAvailDataPoint = sandboxBiDirectionalCommAvailDataPoint
    entityAggregationServiceDataPoint = sandboxAggregationServiceDataPoint
    entityApiGatewayServiceDataPoint = sandboxApiGatewayServiceDataPoint
    entityAuthorizationServiceDataPoint = sandboxAuthorizationServiceDataPoint
    entityCustomerServiceDataPoint = sandboxCustomerServiceDataPoint
    entityDeviceServiceDataPoint = sandboxDeviceServiceDataPoint
    entityRulesServiceDataPoint = sandboxRulesServiceDataPoint
    entityGetDeviceByDeviceIdPerformDataPoint = sandboxGetDeviceByDeviceIdPerformDataPoint
    entityActivateDevicePerformDataPoint = sandboxActivateDevicePerformDataPoint
    entityApplyConfigurationsPerformDataPoint = sandboxApplyConfigurationsPerformDataPoint
    entityApplyFotaPerformDataPoint = sandboxApplyFotaPerformDataPoint
    entityBiDirectionalCommPerformDataPoint = sandboxBiDirectionalCommPerformDataPoint
    entityProxyServerDataPoint = sandboxProxyServerDataPoint
    entityRCTDataPoint = sandboxRCTDataPoint
    entityRCTPerformDataPoint = sandboxRCTPerformDataPoint
    entityDIPDataPoint = sandboxDIPDataPoint
    entityPortalLoginDataPoint = sandboxPortalLoginDataPoint
    entityPortalApplyConfigDataPoint = sandboxPortalApplyConfigDataPoint
    entityPortalCreateConfigDataPoint = sandboxPortalCreateConfigDataPoint
    entityPortalEditConfigDataPoint = sandboxPortalEditConfigDataPoint
    entityPortalViewConfigDataPoint = sandboxPortalViewConfigDataPoint
    entityPortalDeleteConfigDataPoint = sandboxPortalDeleteConfigDataPoint
    entityPortalApplyFirmwareDataPoint = sandboxPortalApplyFirmwareDataPoint
    entityPortalViewFirmwareDataPoint = sandboxPortalViewFirmwareDataPoint
    entityPortalAddDashboardDataPoint = sandboxPortalAddDashboardDataPoint
    entityPortalEditDashboardDataPoint = sandboxPortalEditDashboardPoint
    entityPortalDeleteDashboardDataPoint = sandboxPortalDeleteDashboardDataPoint
    entityPortalLastLocationMapDataPoint = sandboxPortalLastLocationMapDataPoint
    entityPortalDashboardViewMapDataPoint = sandboxPortalDashboardViewMapDataPoint
    entityPortalRealTimeTrackingDataPoint = sandboxPortalRealTimeTrackingDataPoint
    entityPortalHistoricalTrackingDataPoint = sandboxPortalHistoricalTrackingDataPoint
    entityPortalCreateReportDataPoint = sandboxPortalCreateReportDataPoint
    entityPortalEditReportDataPoint = sandboxPortalEditReportDataPoint
    entityPortalDeleteReportDataPoint = sandboxPortalDeleteReportDataPoint
    entityPortalCreateChartDataPoint = sandboxPortalCreateChartDataPoint
    entityPortalSearchEventDataPoint = sandboxPortalSearchEventDataPoint
    entityPortalViewEventDetailsDataPoint = sandboxPortalViewEventDetailsDataPoint
    entityPortalRelaunchActionDataPoint = sandboxPortalRelaunchActionDataPoint
    entityPortalEditDeviceDetailsDataPoint = sandboxPortalEditDeviceDetailsDataPoint
  }
}

export let authToken
export let dynatraceEnvURL
export let entityGetDeviceByDeviceIdAvailDataPoint
export let entityActivateDeviceAvailDataPoint
export let entityActivateDevicePerformDataPoint
export let entityApplyConfigurationsAvailDataPoint
export let entityApplyFotaAvailDataPoint
export let entityBiDirectionalCommAvailDataPoint
export let entityAggregationServiceDataPoint
export let entityApiGatewayServiceDataPoint
export let entityAuthorizationServiceDataPoint
export let entityCustomerServiceDataPoint
export let entityDeviceServiceDataPoint
export let entityRulesServiceDataPoint
export let entityGetDeviceByDeviceIdPerformDataPoint
export let entityApplyConfigurationsPerformDataPoint
export let entityApplyFotaPerformDataPoint
export let entityBiDirectionalCommPerformDataPoint
export let entityProxyServerDataPoint
export let entityRCTDataPoint
export let entityRCTPerformDataPoint
export let entityDIPDataPoint
export let entityPortalLoginDataPoint
export let entityPortalApplyConfigDataPoint
export let entityPortalCreateConfigDataPoint
export let entityPortalEditConfigDataPoint
export let entityPortalViewConfigDataPoint
export let entityPortalDeleteConfigDataPoint
export let entityPortalApplyFirmwareDataPoint
export let entityPortalViewFirmwareDataPoint
export let entityPortalAddDashboardDataPoint
export let entityPortalEditDashboardDataPoint
export let entityPortalDeleteDashboardDataPoint
export let entityPortalLastLocationMapDataPoint
export let entityPortalDashboardViewMapDataPoint
export let entityPortalRealTimeTrackingDataPoint
export let entityPortalHistoricalTrackingDataPoint
export let entityPortalCreateReportDataPoint
export let entityPortalEditReportDataPoint
export let entityPortalDeleteReportDataPoint
export let entityPortalCreateChartDataPoint
export let entityPortalSearchEventDataPoint
export let entityPortalViewEventDetailsDataPoint
export let entityPortalRelaunchActionDataPoint
export let entityPortalEditDeviceDetailsDataPoint
