import 'babel-polyfill'
import * as env from 'config/env'

const prepareEnv = async _ => {
  await env.prepareEnv()
}

prepareEnv()
  .then(() => {
    return import('./main')
  })
  .catch(err => {
    console.log('Failed')
    console.dir(err)
  })
